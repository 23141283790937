<template>
  <div class="yard-body" v-if="showLayout">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <div class="wrapper d-flex align-items-stretch">
      <nav id="detailSidebar">
        <div class="pb-4 pt-1 sidebar-inner-space">
          <ul class="list-unstyled components mb-5 mx-4">

           <li class="mb-5 mt-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled = "this.blockOptions.length === 0"
                @click.stop="printPdf"
              >
                <CIcon name="cil-print"/>
                <span class="ml-1">{{$t('label.print')}}</span>
              </CButton>
            </li> 

            <li>
              <CRow>
                <CCol sm="12" lg="5" class="p-0 text-center d-flex align-items-center">
                  {{$t('label.columns')}}
                </CCol>
                <CCol sm="12" lg="7" class="p-0">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.selectFilter')"
                    class="no-margin-select ml-2"
                    :options="columsFormatted"
                    :value.sync="columns"
                    :disabled = "this.blockOptions.length === 0"
                    @change="filterColumns($event.target.value)"
                  />
                  <loading v-show="isLoadingFiltros" element="select"/>
                </CCol>
              </CRow>
            </li>

            <li class="mb-1">
              <CRow>
                <CCol sm="12" lg="5" class="p-0 text-center d-flex align-items-center">
                  {{$t('label.rows')}}
                </CCol>
                <CCol sm="12" lg="7" class="p-0">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.selectFilter')"
                    class="no-margin-select ml-2"
                    :options="rowsFormatted"
                    :value.sync="rows"
                    :disabled = "this.blockOptions.length === 0"
                    @change="filterRows($event.target.value)"
                  />
                  <loading v-show="isLoadingFiltros" element="select"/>
                </CCol>
              </CRow>
            </li>

          </ul>
        </div>
      </nav>

      <!-- Page Content  -->
      <div id="content">
        <!-- Navbar -->
        <CNavbar
          expandable="lg"
          light class="menu-view-cargo no-margin"
          color="secondary"
        >
           <div class="sidebar-controls">
            <CButton
              color="secondary"
              shape="square"
              class="mr-2"
              size="sm"
              @click.stop="toggleSidebar"
            >
              <CIcon name="cil-menu" />
              <span class="sr-only">Toggle Menu</span>
            </CButton>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="validZoom(true)"
              @click.stop="minusZoom"
            >
              <CIcon name="cil-zoom-out" />
            </CButton>
            <a
              href="#"
              class="zoom-number"
              @click.prevent="resetZoom"
            >
              {{ `${zoom}%` }}
            </a>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="validZoom(false)"
              @click.stop="plusZoom"
            >
              <CIcon name="cil-zoom-in" />
            </CButton>
          </div>
          <CToggler in-navbar @click="collapsed = !collapsed" />
          <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
              <div class="mr-3 ml-2 selects-container">
              </div>
              <div class="position-relative ml-2 selects-container">
                <span>{{$t('label.block')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2"
                  :options="BlockListFormatted"
                  :value.sync="selectedBlock"
                  @change="filterBlock($event.target.value)"
                />
              </div>
              <div class="position-relative ml-2 selects-container">
                <span>{{$t('label.bay')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2"
                  :options="BayListFormatted"
                  :value.sync="selectedBay"
                  :disabled="this.normal"
                  @change="filterBay($event.target.value)"
                />
              </div>
             
            </CNavbarNav>
          </CCollapse>
          <div class="btn-pagination" v-if="visualBlock">
            <span>{{$t('label.page')}} {{ currentPage }} {{$t('label.of')}} {{ numberOfPages }}</span>
            <CButtonGroup class="ml-2">
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.first')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="firstPage"
              >
                <CIcon name="cil-chevron-double-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.previous'),
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="previousPage"
              >
                <CIcon name="cil-arrow-thick-to-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.next'),
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="nextPage"
              >
                <CIcon name="cil-arrow-thick-to-right" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.last')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="lastPage"
              >
                <CIcon name="cil-chevron-double-right" />
              </CButton>
            </CButtonGroup>
          </div>
        </CNavbar>
        <!-- Contenido Interno -->
        <div class="container-fluid">
          <CRow class="mt-5 mb-3">
            <CCol sm="12">
              <div 
                class="zoom-container"
                :class="{ 
                  'grab-bing': !isOgZoom ? true:null,
                }"
                v-dragscroll="!isOgZoom"
              >

                <div v-for="(positions, i) in bayJson" v-if="visualBlock && (i>=from && i<to)">
                  <div
                    class="bay-view-container"
                    :style="{ width: `${zoom}%` }"
                  >


                    <div v-for="(position) in positions" style="width: 100%;">
                      <div v-if="position.BayCode">
                        <bay-grid
                          :col="colNum(position.HeightJson)"
                          :row="rowNum(position.HeightJson)"
                          :items="position.HeightJson ?? []"
                          :maxCol="responsiveCol(position.HeightJson)"
                          showStatus
                          :statusCondition = true
                          :preferences="currentPreferences"
                          :bayCode="position.BayCode"
                          :searchCode = "searchBlock"
                          @update="UpdateSlotModal"
                          :cargoView = "true"
                          :bayFilter = " columns === 1 && rows === 1"
                          :bayView = " columns === 1 && rows === 1"
                          :byBay ="true"
                        />
                      </div>
                      <div  v-if="position.BayCode === undefined"></div>
                    </div>
                  </div>
                </div>      
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import GeneralMixin from '@/_mixins/general';
  import Pagination from '@/_mixins/PaginateMixin';
  import BayMixin from "@/_mixins/bays";
  import Zoom from '@/_mixins/ZoomMixin';
  import { dragscroll } from 'vue-dragscroll';
  import { mapState } from 'vuex';
  import BayGrid from "./bay-view/bay-grid";
  import ldb from 'localdata';

  //DATA-------------------------
  function data() {
    return {
      planimetry:[],
      bayJson:[],
      visualBlock:false,
      from:0,
      to:0,
      leyendModal: false,
      showLayout: true,
      collapsed: false,
      viewType: 'level',
      oversizes: [],
      totalBlock:{
        blockName: '',
        TotalCont20: '',
        TotalCont40: '',
        TotalCont45: '',
        TotalTeus: ''
      },
      //Slot modal
      slotModal: false,
      containerData: null,
      //Preferencias
      searchCode: '',
      views: [],
      view: [],
      preferences: [],
      selectedOptions: [],
      modalPref: false,
      //Filter
      multiple: false,
      normal: false,
      columns: 5,
      rows: 2,
      bayContainerType: '',
      generalFilter: false,
      typeBay: [
        {
          value : process.env.VUE_APP_YARD_SLOT_STATUS,
          nameEn: 'OCCUPIED',
          nameEs: 'OCUPADO',
        },
        {
          value : process.env.VUE_APP_YARD_SLOT_STATUS_ACTIVE,
          nameEn: 'AVAILABLE',
          nameEs: 'DISPONIBLE',
        },
        {
          value : process.env.VUE_APP_YARD_SLOT_STATUS_SOBREDIMENTION,
          nameEn: 'OVERSIZE',
          nameEs: 'SOBREDIMENSIÓN',
        },
      ],
      //Usadas para los filter de preferencias.
      blockOptions: [],
      selectedBlock: '',
      bayOptions: [],
      selectedBay: '',
      filter: [],
      isLoadingFiltros: false,
      isLoadingPuertos: false,
      loading: false,
      //Pagination
      numberPerPage: 1,
      //Search
      blockFilter:0,
      searchBlock:'',
      containerSearch: {}, 
      baysPositions: [],
      successFound: false,
      blockItems: 0,
    }
  }

  async function getYardPlan() {
    this.loading = true;
    await this.$http
      .get("YardBlockList-by-YardId", {
        YardId: this.getYardId,
      })
      .then((response) => {
        this.blockOptions = response.data.data;

        if(this.selectedBlock === "")
          this.selectedBlock = this.blockOptions[0]?.YardBlockId;

        else{
          let  dataBlock = this.blockOptions.filter((item)=>{return item?.YardBlockId === this.selectedBlock});
          this.selectedBlock = dataBlock[0]?.YardBlockId
        }

        this.oversizeFind(this.blockOptions);
        this.blockAdjustment();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).then(() => {
        this.loading = false;
      });
      this.loading = false;
  }

  function colNum(positions){
    let count = 0;

    positions?.map(item =>{
      if(count < item?.PositionJson?.length)
        count = item?.PositionJson?.length;
    });
    return count;
  }

  function getViews() {
    this.$http
      .ejecutar("GET", "YardManagementView-list", {
        YardId: this.getYardId,
      })
      .then((response) => {
        this.views = response.data.data;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      });
  }

  function rowNum(positions){
    return positions?.length > 0 ? positions?.length :  0;
  }

  function responsiveCol(positions) {
    let colNum = this.colNum(positions);
    return colNum;
  }

  function filterBlock(event) {
    this.selectedBlock = event;
    this.collapsed = false;
    this.normal = false;
    this.columns = 5;
    this.rows = 2,
    this.selectedBay = "";
    this.blockAdjustment();
  }

  async function filterBay(event) {
    this.selectedBay = event;

    if(this.selectedBay != ""){
      let bay = this.bayOptions.filter((item)=>{return item.BayCode === this.selectedBay});
      this.columns = 1;
      this.rows = 1;
      await this.adjustmentMethod(bay);
      
      this.zoom = 100;
      this.currentPage = 1;
      this.from = 0;
      this.to = this.rows;
      this.visualBlock = true;
      this.searchBlock = '';
    }
    else{
      this.columns = 5;
      this.rows = 2,
      this.blockAdjustment();
    }
    
  }

  function filterColumns(event) {
    this.columns = parseInt(event);
    this.normal = false;
    this.selectedBay = "";
    this.blockAdjustment();
  }

  function filterRows(event) {
    this.rows = parseInt(event);
    this.normal = false;
    this.selectedBay = "";
    this.blockAdjustment();
  }

  function bayContainerFilter(event) {
    this.bayContainerType = event;
    this.generalFilter = false;
    event === "" && this.blockAdjustment();

    if(this.selectedBay != "") {
      this.selectedBay = "";
      this.columns = 5;
      this.rows = 2,
      this.blockAdjustment();
    }
  }

  //CONTROL DE BLOQUES
  async function blockAdjustment(filter = false, YardSlotStatus = '') {
    this.visualBlock = false;

    if(this.selectedBlock != "" && this.selectedBlock){
      this.planimetry = this.blockOptions.filter((item)=>{return item?.YardBlockId === this.selectedBlock});
      this.totalBlock.TotalCont20 = '';
      this.totalBlock.TotalCont40 = '';
      this.totalBlock.TotalCont45 = '';
      this.totalBlock.TotalTeus = '';
      this.totalBlock.blockName = this.planimetry[0].BlockAlias;
      
      await this.adjustmentMethod(this.planimetry[0]?.BayJson, filter, YardSlotStatus);
      this.bayOptions = this.planimetry[0]?.BayJson;
    }
    this.zoom = 100;
    this.currentPage = 1;
    this.from = 0;
    this.to = this.rows;
    this.visualBlock = true;
    this.searchBlock = '';
  }

  //RENDERIZADO DE CUADROS
  function adjustmentMethod(blockItems, filter = false, YardSlotStatus = "") {
    
    if(blockItems == undefined)
      return false;
    
    this.bayJson = [];

    let jsons = [], contJsons = 1, status = [], sobredimentionLength = 0;

    this.blockItems = blockItems.length;

    for (let i = 0; i < blockItems.length; i++) {

      //CONDICIÓN PARA EL FILTROS GENERALES
      if(filter)
        status = blockItems[i]?.HeightJson.filter(element => {return (element?.PositionJson.filter(el => {
          return ( 
            (process.env.VUE_APP_YARD_SLOT_STATUS === YardSlotStatus) ? 
              process.env.VUE_APP_YARD_SLOT_STATUS === el.YardSlotStatus || process.env.VUE_APP_YARD_SLOT_STATUS_SOBREDIMENTION === el.YardSlotStatus 
                :
              el.YardSlotStatus === YardSlotStatus
          )
        })).length>0 })

      //CONDICIÓN PARA CUANDO LOS CONTENEDORES SON SOBREDIMENSIONADOS Y SABER QUE POSICIONES OCUPA
      // this.oversizes?.map(() => {  
      //   if(this.oversizes[sobredimentionLength] && (i === this.oversizes[sobredimentionLength]?.bay && this.selectedBlock === this.oversizes[sobredimentionLength]?.block)){
      //     this.addPositionsOversize(i, sobredimentionLength, blockItems);
      //     sobredimentionLength += 1;
      //   }
      // })

      //REGISTRO DE DATA POR BAY
      if(status.length>0 || !filter)  
      
        jsons.push(
          {
            ...blockItems[i],
            BayCode : blockItems[i].BayCode,
            HeightJson : blockItems[i].HeightJson
          }
        );
      
      //CONTROL DE ITEMS
      if(contJsons ===  this.columns && jsons.length === contJsons){
        this.bayJson.push(jsons);
        contJsons = 1;
        jsons = [];
      }
      else{

        if((i+1) != blockItems.length && (status.length>0 || !filter))
          contJsons++;
        
        else{
          if((i+1) === blockItems.length && jsons.length>0){
            contJsons = (this.columns - jsons.length);
          
            for (let i = 1; i <= contJsons; i++) 
              jsons.push({});
              
            this.bayJson.push(jsons);
          }
        }
      }
    }
  }

  //BUSCADOR DE CONTENEDORES SOBREDIMENSIONADOS
  function oversizeFind(blocks){
    this.oversizes = [];

    blocks.map((rows) => {    
      rows.BayJson.map((bay, bayIndex) => {    
        bay?.HeightJson?.map((height, heightIndex) => {   
          height.PositionJson?.map((PositionJson, position) => {  
            if(  
                 PositionJson?.CargoJson?.length > 0 && 
                 PositionJson.YardSlotStatus === process.env.VUE_APP_YARD_SLOT_STATUS &&
                 (PositionJson?.CargoJson[0].DimBack != null  || 
                  PositionJson?.CargoJson[0].DimFront != null || 
                  PositionJson?.CargoJson[0].DimLeft != null  || 
                  PositionJson?.CargoJson[0].DimRight != null || 
                  PositionJson?.CargoJson[0].DimTop != null 
                 )
            )
            {
              this.oversizes.push({'block': rows?.YardBlockId, 'bay' : bayIndex, 'height' : heightIndex, 'PositionJson' : position});
            }
          })
        })
      })
    })
  }

  function toggleSidebar() {
    document.querySelector("#detailSidebar").classList.toggle('active');
  }

  function printPdf(){
    let pageInfo = {
      bayJson: this.bayJson,
      totalBlock: this.totalBlock,
      from: this.from,
      to: this.to,
      columns: this.columns,
      rows: this.rows,
      blockItems: this.blockItems,
      details: {...this.yardSelected},
      view : [],
      type: 'bay-planimetry',
  };
  
  ldb.set('pageInfo', JSON.stringify(pageInfo));
  let routeData = this.$router.resolve({name: 'PdfYardPlan'});
  window.open(routeData.href, '_blank');
 
  }
  
  function refreshBaysList(){}

  function toggleMultiple() {
    this.multiple = !this.multiple;
  }

  function toggleNormales() {
    if(this.blockOptions.length === 0)
      return false;

    if(!this.normal){ this.bayContainerType = ''; this.generalFilter = false; }
    else this.generalFilter === true && this.blockAdjustment();

    if(this.selectedBay != "") {
      this.selectedBay = "";
      this.columns = 5;
      this.rows = 2,
      this.blockAdjustment();
    }

    this.normal = !this.normal;
  }

  function aplicaPlano(val){
    this.generalFilter = val;
    if(val) this.blockAdjustment(true, this.bayContainerType);
    else this.blockAdjustment();
  }

  function firstPage() {
    this.currentPage = 1;
    this.from = 0; 
    this.to = this.rows;  
  }
  function previousPage() {
    this.currentPage--;
    this.from = (this.from-this.rows);
    this.to = (this.to-this.rows);
  }
  function nextPage() {
    this.currentPage++;
    this.from =  this.to;
    this.to = (this.to + this.rows);
  }
  function lastPage() {
    this.from =  (this.numberOfPages*this.rows)-this.rows; 
    this.to = this.numberOfPages*this.rows;  
    this.currentPage = this.numberOfPages;
  }

  function Update() {
    this.leyendModal = false;
  }

  function UpdateSlotModal(item) {
    this.containerData = item;
    this.slotModal = true;
  }

  function handleSelecteds(options) {
    this.selectedOptions = [...options];
  }

  function isEmpty(arr) {
    return arr.length === 0;
  }

  function validZoom(minus) {
    if(minus){
      return ((this.zoom === 80) || this.columns < 4)
    }
    else{
      return (this.zoom === 250 || (this.columns < 4 && this.zoom === 100 ) || (this.columns == 4 && this.zoom === 130 ) || (this.columns == 5 && this.zoom === 190 ) || (this.columns == 6 && this.zoom === 220))
    }
  }

  //SEARCH CONTAINERS
  function setSearch() {
    this.loading = true;
    let found = [];
    this.baysPositions = [];
    this.blockFilter = 0;
    this.searchBlock = '';

    //GLOBAL SEARCH 
    for (let a = 0; a < this.blockOptions.length; a++) {
      let bayJson = this.blockOptions[a].BayJson;

      for (let b = 0; b < bayJson.length; b++) {
        let bay = bayJson[b].HeightJson ?? -1;

        for (let c = 0; c < bay.length; c++) {
          let PositionJson =  bay[c].PositionJson;

          for (let d = 0; d < PositionJson.length; d++) {
            let item = PositionJson[d];

            found = item?.CargoJson?.filter((content) =>  (
              content.ContainerCode === this.searchCode
            ))

            if(found.length > 0){
              if(Object.entries(this.containerSearch).length== 0 ) this.containerSearch = found[0];
              found = [];
              this.successFound = true;
            } 
          }
        }
        if(this.successFound){
          this.baysPositions.push(b);
          this.successFound = false;
        }
      }
      if(this.baysPositions.length>0){
        this.blockFilter = a;
        break;
      }
    }
      
    //AJUSTES PARA EL CONTENEDOR ENCONTRADO
    if(this.baysPositions.length>0){
      this.selectedBay = "";
      this.selectedBlock = this.blockOptions[this.blockFilter]?.YardBlockId;
      this.adjustmentMethod(this.blockOptions[this.blockFilter]?.BayJson, false, '');
      this.searchBlock = this.searchCode;

      let items = 0, contPage = 1, page = 1;

      for (let i = 1; i <= this.bayJson.length; i++) {
        items = items + this.columns;

        if(items>this.baysPositions[0]){
          this.currentPage = page;
          this.to = this.rows;
          this.from = (this.to * page) - this.rows;
          this.to = this.to * page;
          break;
        }

        if((this.columns* this.rows) * contPage === items){
          page += 1;
          contPage += 1;
        }
      }

    } else {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: this.$t('label.noContainerFound'),
        type: "error",
      });
    }
    this.loading = false;
  }

  function clean() {
    //LIMPIADO DE FILTROS
    this.searchCode = "";
    this.selectedBlock = ""
    this.visualBlock = false;
    this.bayContainerType = '';
    this.view = '';
    this.preferences = [];
    this.selectedOptions = [];
    this.columns = 5;
    this.rows = 2;
    this.to = this.rows;
    this.normal = false;
    this.selectedBay = "";
  }

  //Computeds
  function currentPreferences() {
    return this.selectedOptions;
  }
  function numberOfPages() {
    let resultPage = Math.ceil(this.bayJson.length / this.rows);
    return resultPage>0 ? resultPage : 1;
  }
  function leftBtnsDisabled() {
    return this.currentPage == 1 || this.filterItems?.length == 0;
  }
  function rightBtnsDisabled() {
    return this.currentPage == this.numberOfPages || this.filterItems?.length == 0;
  }

  function BlockListFormatted(){
      var chart = [];

      this.blockOptions.map(function(e){
          chart.push({
            value: e?.YardBlockId, 
            label: e.BlockAlias, 
          })     
      })
      return chart;
  }

  function BayListFormatted(){

    if(this.bayOptions.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.bayOptions.map(function(e){
          chart.push({
            value: e.BayCode, 
            label: e.BayCode, 
          })     
      })
      return chart;
    }
  }

  function columsFormatted(){
    var chart = []

    for (let i = 1; i <= 7; i++) 
        chart.push({
          value: i, 
          label: `${i}`, 
        });  

    return chart;
  }

  function rowsFormatted(){
    var chart = []

    for (let i = 1; i <= 4; i++) 
        chart.push({
          value: i, 
          label: `${i}`, 
        });  

    return chart;
  }

  function bayContainerFilterFormatted(){
    var chart = [];
    let _lang = this.$i18n.locale;
    
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    
    this.typeBay.map(function(e){
        chart.push({
          value: e.value, 
          label: _lang == 'es' ? e.nameEs : e.nameEn, 
        })     
    })

    return chart;
  }

  function viewsOptions() {
    return this.views.map((item) =>
      Object.assign({}, item, {
        label: this.$i18n.locale == 'es' ? item.YardViewNameEs : item.YardViewNameEn,
        value: item.YardViewId,
      })
    );
  }

  function getYardId() {
      return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
  }


  export default{
      name: 'planimetry-index',
      data,
      mixins: [GeneralMixin],
      components: {
        BayGrid,
      },
      mixins: [BayMixin, Pagination, Zoom],
      directives: {
        dragscroll
      },
      props: {
        modulo: {
          type: String,
          default: 'stowage'
        }
      },
      methods: {
        validZoom,
        toggleSidebar,
        printPdf,
        refreshBaysList,
        toggleMultiple,
        toggleNormales,
        getYardPlan,
        colNum,
        rowNum,
        responsiveCol,
        filterBlock,
        filterColumns,
        filterRows,
        blockAdjustment,
        adjustmentMethod,
        firstPage,
        previousPage,
        nextPage,
        lastPage,
        getViews,
        Update,
        handleSelecteds,
        isEmpty,
        UpdateSlotModal,
        setSearch,
        aplicaPlano,
        bayContainerFilter,
        filterBay,
        oversizeFind,
        clean
      },
      computed: {
        currentPreferences,
        columsFormatted,
        rowsFormatted,
        bayContainerFilterFormatted,
        numberOfPages,
        leftBtnsDisabled,
        rightBtnsDisabled,
        BlockListFormatted,
        BayListFormatted,
        viewsOptions,
        getYardId,
        ...mapState({
            yardData: state => state.yardManagement.yardData,
            currentTab: state => state.yardManagement.tabIndex,
            currentPrincipalTab: state => state.yardManagement.yardCollapse,
            activeTab: state => state.yard.tabIndex,
            yardSelected: state => state.yard.yardSelected
        })
      },
      watch:{
        activeTab: function (newTab) {
          if (newTab == 6) {
            this.clean();

            //SERVICIO INICIAL DE BLOQUES
            this.getYardPlan();

            //INICIACIÓN DE FILTROS
            this.getViews();
          }
        },
      }
  }
</script>
<style scoped>
/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  overflow: hidden;
}
.bay-view {
  position: relative;
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 250px;
  position: relative;
}
.bay {
  position: relative;
  margin: 0 0 5px 5px;
  width: 100%;
  padding: 10px 0;
  /* overflow: hidden; */
}
.bay-info {
  /* position: absolute;
  bottom: -18px;
  right: -15px; */
  width: 165px;
  margin-left: auto
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Zoom */
.zoom-container {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  /* scrollbar-width: thin; */
}
.zoom-center {
  display: flex;
  justify-content: center;
}
.sidebar-controls {
  display: flex;
  
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}
.zoom-number {
  padding:5px;
  background-color: #d6d7db;
  border: 1px solid #636f83;
}
.zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}

/* Sidebar */
.yard-body #detailSidebar {
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #e6e3e3 !important;
  background-image: none;
  border: 1px solid #000;
  transition: all 0.3s;
  z-index: 1;
}
.yard-body #content {
  min-height: 834px;
}
.sidebar-inner-space {
  /* margin-top: 2rem !important */
  margin-top: 0;
}
.yard-body #detailSidebar.active {
  width: 0;
	height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0; /* sólo en caso de elementos inline-block */
  overflow: hidden;
  margin-left: -200px;
}
.btn-sidebar-detail {
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}

/* Navbar */
.menu-view-cargo.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  border-top: none;
  box-shadow: 0 6px 6px -6px #000;
}
.menu-view-cargo.no-margin {
  box-shadow: none;
  /* margin-bottom: 0; */
}
.nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
}
.nav-internal-dropdown {
  background-color: #f5f7fa !important;
}
.no-margin-select {
  margin-bottom: 0;
}
.selects-container {
  display:flex; 
  justify-content: center;
  align-items: center;
}

/* Pagination */
.btn-pagination {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Filtros multiples */
.filter-collapse {
  width: 100%;
  cursor: pointer;
  color: #4e4c4d;
  background-color: #f8f6f7;
  border: 1px solid #abaaa8;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selects-container-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.item-number-icon { /* Center and .svg */
    width: 70%;
    height: 70%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

</style>